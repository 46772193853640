/**
 * Trigger confirmation when user accepts/rejects
 */

$(document).on('click', '#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection, #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll', function() {
    var $confirmation = $('<div />').addClass('cookie-accept-confirmation').append(
        $('<div />').addClass('content-wrapper').append(
            $('<h2 />').text(window.hastens_globals.cookieAcceptHeading),
            $('<p />').text(window.hastens_globals.cookieAcceptBody),
        ),
    );

    $('body').append($confirmation);
    setTimeout(function() {
        $confirmation.fadeOut(400, function() {
            $confirmation.remove();
        });
    }, 4000);
});

$(document).on('click', '#CybotCookiebotDialogBodyButtonDecline', function() {
    var $confirmation = $('<div />').addClass('cookie-reject-confirmation').append(
        $('<div />').addClass('content-wrapper').append(
            $('<h2 />').text(window.hastens_globals.cookieRejectHeading),
            $('<p />').text(window.hastens_globals.cookieRejectBody),
        ),
    );

    $('body').append($confirmation);
    setTimeout(function() {
        $confirmation.fadeOut(400, function() {
            $confirmation.remove();
        });
    }, 4000);
});
